.App {
  text-align: center;
  position: relative;
  font-size: 16px;
}

hr {border:0;border-top:1px solid #000;margin:0;height:1px;}

/* ------------------------------------------------------------ */
/* HELPER CLASSES */
/* ------------------------------------------------------------ */
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.pointer {
  cursor: pointer;
}

.group:after {
  content: "";
  display: table;
  clear: both;
}

/* ------------------------------------------------------------ */
/* CURRENT DAY VIEW */
/* ------------------------------------------------------------ */
.CurrentDayView {
  width: 82vw;
  display: block;
  text-align: left;
  font-size: 6vw;
  margin-top: 6vw;
  box-sizing: border-box;
}

.CurrentDayView * { box-sizing: border-box; }

.CurrentDayView .DateHolder {
  /* background-color: orange; */
  margin: 0;
  line-height: 6vw;
  padding: 1vw 0;
}

.CurrentDayView hr {
  margin: 0;
  height: 0.4vw;
  background-color: black;
}

.CurrentDayView .month {
  text-transform: uppercase;
  font-weight: 700;
  margin-right: 2vw;
}

.CurrentDayView .year {
  font-weight: 200;
  margin-left: 2vw;
}

.CurrentDayView .weekday {
  font-weight: 200;
  font-size: 4vw
}

.InterdimensionCounterHolder {
  font-size: 4vw;
}

.InterdimensionCounterHolder .ResetDimentionDays {
  float: right;
  font-weight: 200;
  color: #852929;
  cursor: pointer;
}

/* ------------------------------------------------------------ */
/* CALENDAR */
/* ------------------------------------------------------------ */
.CalendarView {
  margin-top: 6vh;
}

.CalendarMonthYear { text-align: left; position: relative; }
.CalendarMonthYear .month {
  font-size: calc(8vw);
  font-weight: 600;
  text-transform: uppercase;
  margin-left: calc(4vw)
}

.CalendarMonthYear .year {
  font-size: calc(6vw);
  font-weight: 200;
  margin-left: calc(1vw)
}

.CalendarMonthYear .today {
  font-size: calc(4vw);
  font-weight: 200;
  position: absolute;
  bottom: 1vw; right: 4vw;
  text-transform: uppercase;
  cursor: pointer;
}

.CalendarView .weekday {
  display: block;
  height: '100%';
  font-size: 3vw;
  color: #777;
  font-weight: 200;
}

.UpdateCurrentDate, .UpdateBank {
  width: 83vw;
  font-size: calc(4vw);
  text-transform: uppercase;
  font-weight: 700;
  margin: 2vh auto 0 auto;
  border: solid 0.5vw black;
  padding: 0.8vh 0;
  cursor: pointer;
}

/* ------------------------------------------------------------ */
/* SCHEDULE */
/* ------------------------------------------------------------ */
.ScheduleView {
  display: block;
  margin-top: 4vh;
  margin-bottom: 4vh;
  width: 84vw;
}

.darkmode {
  background-color: #ededed;
  /* color: #fff; */
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.redmode { color: #f23838; }
.bluemode { color: rgb(16, 75, 148); }

.ScheduleInput, .BankInput {
  display: block;
  width: 100%;
  max-width: 100%;
  min-height: 20vw;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  border: solid 0.5vw black;
  border-radius: 0px;
  padding: 15px;
  font-size: 3vw;
}

.UpdateJournal {
  width: 83vw;
  font-size: calc(4vw);
  text-transform: uppercase;
  font-weight: 700;
  margin: 10px auto 0 auto;
  border: solid 0.5vw black;
  padding: 0.8vh 0;
  cursor: pointer;
}

.darkmode .ScheduleInput {
  /* border: solid 0.5vw white; */
  background-color: #ededed;
  /* color: white; */
}
/* .darkmode .UpdateJournal { border: solid 0.5vw white; } */

.redmode .ScheduleInput {
  border: solid 0.5vw #f23838;
  color: #f23838;
}
.redmode .UpdateJournal { border: solid 0.5vw #f23838; }

.bluemode .ScheduleInput {
  border: solid 0.5vw rgb(16, 75, 148);
  color: rgb(16, 75, 148);
}
.bluemode .UpdateJournal { border: solid 0.5vw rgb(16, 75, 148); }

.JournalLabel {
  display: flex;
  justify-content: space-between;
  font-size: 4vw;
}

.JournalLabel .JournalType {
  font-weight: 700;
}

.JournalLabel .JournalDate .weekday {
  font-size: 3vw;
  font-weight: 200;
}

/* .JournalLabel .JournalDate .year {
  font-weight: 200;
} */


/* ------------------------------------------------------------ */
/* MISC */
/* ------------------------------------------------------------ */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: #000;
}

.App-link {
  color: #000;
}

/* ------------------------------------------------------------ */
/* BANK */
/* ------------------------------------------------------------ */

.BankView {
  display: block;
  margin-top: 4vh;
  margin-bottom: 4vh;
  width: 84vw;
}

.bankRow {
  display: flex;
  padding: 5px 0;
  border-bottom: dashed 1px black;
}

.bankRow span {
  text-align: left;
  margin: 5px 0;
}

.bankRow .bankValue {
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bankRow .bankInput {
  width: 60%;
  display: flex;
  justify-content: center;
}

.bankRow .bankInput input {
  border-radius: 0px;
  border: solid 1px #000000;
  padding: 0 5px;
}

.bankRow .bankAction {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.bankRow .bankAction .button {
  width: 90%;
  padding: 2vw 0;
  line-height: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.bankMisc {
  text-align: left;
  margin-top: 15px;
}

.bankMisc textarea {
  width: 100%;
  border-radius: 0;
}

.BankView.personal hr { border-top-color: rgb(16, 75, 148); }
.BankView.personal * { color: rgb(16, 75, 148); }
.BankView.personal .bankRow { border-bottom-color: rgb(16, 75, 148); }
.BankView.personal .bankRow .bankInput input { border-color: rgb(16, 75, 148); }
.BankView.personal .BankInput { border-color: rgb(16, 75, 148); }
.BankView.personal .UpdateBank { border-color: rgb(16, 75, 148); }

.BankView.box hr { border-top-color: #268057; }
.BankView.box * { color: #268057; }
.BankView.box .bankRow { border-bottom-color: #268057; }
.BankView.box .bankRow .bankInput input { border-color: #268057; }
.BankView.box .BankInput { border-color: #268057; }
.BankView.box .UpdateBank { border-color: #268057; }

.BankView.bank hr { border-top-color: #f23838; }
.BankView.bank * { color: #f23838; }
.BankView.bank .bankRow { border-bottom-color: #f23838; }
.BankView.bank .bankRow .bankInput input { border-color: #f23838; }
.BankView.bank .BankInput { border-color: #f23838; }
.BankView.bank .UpdateBank { border-color: #f23838; }

.BankView.heimat hr { border-top-color: #8c08f1; }
.BankView.heimat * { color: #8c08f1; }
.BankView.heimat .bankRow { border-bottom-color: #8c08f1; }
.BankView.heimat .bankRow .bankInput input { border-color: #8c08f1; }
.BankView.heimat .BankInput { border-color: #8c08f1; }
.BankView.heimat .UpdateBank { border-color: #8c08f1; }

.BankView.apartment hr { border-top-color: #555555; }
.BankView.apartment * { color: #555555; }
.BankView.apartment .bankRow { border-bottom-color: #555555; }
.BankView.apartment .bankRow .bankInput input { border-color: #555555; }
.BankView.apartment .BankInput { border-color: #555555; }
.BankView.apartment .UpdateBank { border-color: #555555; }

.BankView.elkoris hr { border-top-color: #106c43; }
.BankView.elkoris * { color: #106c43; }
.BankView.elkoris .bankRow { border-bottom-color: #106c43; }
.BankView.elkoris .bankRow .bankInput input { border-color: #106c43; }
.BankView.elkoris .BankInput { border-color: #106c43; }
.BankView.elkoris .UpdateBank { border-color: #106c43; }

.BankView.tybalt hr { border-top-color: #976c00; }
.BankView.tybalt * { color: #976c00; }
.BankView.tybalt .bankRow { border-bottom-color: #976c00; }
.BankView.tybalt .bankRow .bankInput input { border-color: #976c00; }
.BankView.tybalt .BankInput { border-color: #976c00; }
.BankView.tybalt .UpdateBank { border-color: #976c00; }

.BankView.castle hr { border-top-color: #b75301; }
.BankView.castle * { color: #b75301; }
.BankView.castle .bankRow { border-bottom-color: #b75301; }
.BankView.castle .bankRow .bankInput input { border-color: #b75301; }
.BankView.castle .BankInput { border-color: #b75301; }
.BankView.castle .UpdateBank { border-color: #b75301; }

.BankView .button.yellow { color: #ffffff !important; }

.red { color: white; background-color: #f23838; }
.green { color: white; background-color: #3fb48d; }
.yellow { color: white; background-color: #d1a00d; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
